<template>
  <div style="background-color: white; position: absolute; width: 100%; min-height:100%">
    <br>
    <div id="desaparecer" style="width: 100%;">
      <div class="row">
        <div class="col-md-3 col-sm-4 col-xs-6">
          <div class="btn-group">
            <button class="btn btn-danger" @click="cerrar()">
              <i class="mdi mdi-close"></i> Cerrar
            </button>
            <button class="btn btn-primary" @click="imprimir()">
              <i class="mdi mdi-printer"></i> Imprimir
            </button>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-6 text-left">
          <div class="input-group" >
            <span class="input-group-text">
              Escala
            </span>
            <select class="form-select" v-model="tipo_escala" @change="modificarEscala()">
              <option :value="1">13%</option>
              <option :value="2">23%</option>
              <option :value="3">47%</option>
              <option :value="4">100%</option>
              <option :value="5">Personalizado</option>
            </select>
          </div>
        </div>
        <div class="col-md-3" v-show="tipo_escala == 5">
          <div class="row">
            <div class="col-sm-6 pt-1">
              <input
                type="range"
                min="11" max="100"
                class="form-control"
                v-model="escala"
                @change="modificarEscala()"
              />
            </div>
            <div class="col-sm-6">
              <div class="input-group">
                <input
                  name="escala" ref="escala" 
                  type="number" class="form-control"
                  @change="modificarEscala()"
                  v-model="escala" min="11" max="100"
                />
                <span class="input-group-text">
                  %
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="scale" id="scale">
      <canvas
        v-for="(ficha,index) in fichas"
        :key="`fichas-${index+1}`"
        :id="`x-${index+1}`"
        :width="ficha.layout.width"
        :height="ficha.layout.height"
        :style="`border-radius: ${ficha.layout.borde_canvas}px; margin: ${imprimir_unica_ficha == 1 ? 0: 1}px;`"
      >
        Hola, su navegador no soporta canvas
      </canvas>
      <div style="display: none;">
        <qrcode-vue
          v-for="(ficha,index) in fichas"
          :key="`qr-${index+1}`"
          :id="`qr-code-${index+1}`"
          :value="`http://${ficha.hotspot.lan.direccion_ip.split('/',1)}/login?username=${ficha.pin}&password=${ficha.clave != null ? ficha.clave : ''}`"
          :size="90"
          :background="'white'"
          :foreground="'black'"
        ></qrcode-vue>
      </div>
    </div>
  </div>
</template>

<script src="./ImprimirFichas.js"></script>

<style scoped>
canvas {
  border: 1px solid black;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.scale {
  zoom: 1;
  -moz-transform: scale(1); 
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0px 0px;  /* move the origin to top-left */
}

@media print {
  #desaparecer {
      display: none;
  }
  br {
    display: none;
  }
}
/**
  * Remueve la URL de la página
  */
@page {
  size: auto;
  margin: 0;
}
</style>