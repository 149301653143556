/*
 * ImprimirFichas.js
 */
import FichasSrv from '@/services/hotspot/FichasSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import usuarioicon from '@/assets/img/usuario.png'
import accounting from 'accounting/accounting.js'
import QrcodeVue from 'qrcode.vue'
import { APIHOTS as API } from '@/API.js'
export default {
  name: 'ImprimirFichas',
  components : { QrcodeVue },
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      usuarioicon: usuarioicon,
      
      ids_fichas: [],
      fichas: [],
      escala:  13,
      tipo_escala: 2,
      imprimir_unica_ficha: 0,

      nombre_empresa: '',

      datos: [
        {
          titulo: '$usuario$',
          valor:function (ficha) {
            return ficha.pin || ''
          }
        },
        {
          titulo: '$clave$',
          valor:function (ficha) {
            return ficha.clave || ''
          }
        },
        {
          titulo: '$empresa$',
          valor:function (ficha) {
            return ficha.nombre_empresa || ''
          }
        },
        {
          titulo: '$id$',
          valor:function (ficha) {
            return ficha.id || ''
          }
        },
        {
          titulo: '$pin$',
          valor:function (ficha) {
            return ficha.pin || ''
          }
        },
        {
          titulo: '$tiempo$',
          valor:function (ficha) {
            let tmp = ficha.tiempo || 0,
              dias = Math.floor(tmp / 86400),
              horas = Math.floor((tmp / 3600) % 24),
              minutos = Math.floor((tmp / 60) % 60)
          
            return dias+' dia '+horas+' hrs '+minutos+' min'
          }
        },
        {
          titulo: '$expiracion$',
          valor:function (ficha) {
            let tmp = ficha.expiracion || 0,
              dias = Math.floor(tmp / 86400),
              horas = Math.floor((tmp / 3600) % 24),
              minutos = Math.floor((tmp / 60) % 60)
          
            return dias+' dia '+horas+' hrs '+minutos+' min'
          }
        },
        {
          titulo: '$limite_trafico$',
          valor:function (ficha) {
            if(ficha.limite_trafico_total == null) {
              return 'Sin limite'
            }

            let gb = Math.floor((ficha.limite_trafico_total / 1073741824))
            if(gb >= 1) {
              return gb+' GB'
            }

            let mb = Math.floor((ficha.limite_trafico_total / 1048576 ) % 1024)
            if(mb >= 1) {
              return mb+' MB'
            }

            let kb = Math.floor((ficha.limite_trafico_total / 1024 ) % 1024)
            if(kb >= 1) {
              return kb+' kB'
            }
          }
        },
        {
          titulo: '$precio$',
          valor:function (ficha, moneda) {
            return accounting.formatMoney((ficha.precio || 0), { symbol: moneda.simbolo, miles: ",", decimal: "." })
          }
        }
      ]
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created() {
    var self = this
    if (self.$route.query.ids_fichas) self.ids_fichas = self.$route.query.ids_fichas

    if(typeof self.ids_fichas == 'string') {
      self.ids_fichas = []
      self.ids_fichas.push(self.$route.query.ids_fichas)
    }

    if (self.$route.query.imprimir_unica_ficha == 1) {
      self.imprimir_unica_ficha = 1

      let tipoEscala = window.localStorage.getItem('argusblack.hotspot.fichas.ImprimirFichas.tipo_escala')
      if (tipoEscala != undefined) self.tipo_escala = tipoEscala
      else self.tipo_escala = 4 
    }

    self.cargarNombreEmpresa(function() {
      self.refrescarFichas()
    })
  },
  methods: {
    agregarDegradado(ficha, lienzo) {
      let tipo_fondo = ficha.layout.tipo_fondo
      let y1 = null       // Cordenada final Y
      let degradado = null 

      switch(tipo_fondo) {
        case 1: y1 = 10;  break;
        case 2: y1 = 200; break;
        // Evita agregar el degradado 
        case 3: return; break;
      }

      degradado = lienzo.createLinearGradient(0,0,0,y1);

      switch(tipo_fondo) {
        case 1:
          degradado.addColorStop(1, ficha.layout.primer_color_fondo)
        break;
        case 2:
          degradado.addColorStop(0.3, ficha.layout.primer_color_fondo)
          degradado.addColorStop(1, ficha.layout.segundo_color_fondo)
        break;
      }

      lienzo.fillStyle=degradado;
      lienzo.fillRect(0,0,500,300);
    },

    agregarFigura: function(ficha, lienzo, index){
      var self = this,
          layout = ficha.layout

      layout.figuras.forEach(figura => {
        switch(figura.tipo) {
          case 1:
            self.cuadradoCanvas(
              lienzo, figura.x, figura.y, figura.width,
              figura.height, figura.borde, figura.color,
              figura.fondo, figura.grosor_borde
            )
          break;
          case 2:
            self.cuadradoCanvas(
              lienzo, figura.x, figura.y, figura.width,
              figura.height, figura.borde, figura.color,
              figura.fondo, figura.grosor_borde
            )
          break;
          case 3:
            self.circuloCanvas(
              lienzo, figura.x, figura.y, figura.radio,
              figura.color, figura.fondo, figura.grosor_borde
            )
          break;
          case 4:
            self.trianguloCanvas(
              lienzo, figura.x, figura.y, figura.width,
              figura.height, figura.color, figura.fondo,
              figura.grosor_borde
            )
          break;
          case 5:
            self.codigoQRCanvas(
              lienzo, index, figura.x, figura.y, figura.width,
              figura.height, figura.color
            )
          break;
        }
      })
    },

    cargarNombreEmpresa: function(callback) {
      var self = this

      SistemaSrv.nombreEmpresa().then(response => {
        self.nombre_empresa = response.data

        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el nombre de la empresa'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cuadradoCanvas:function(lienzo, x, y, width, height, radius=5, color, fondo='si', grosor_borde) {
      var self = this

      radius = parseInt(radius)

      lienzo.beginPath()
      lienzo.moveTo(x + radius, y)
      lienzo.lineTo(x + width - radius, y)
      lienzo.quadraticCurveTo(x + width, y, x + width, y + radius)
      lienzo.lineTo(x + width, y + height - radius)
      lienzo.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
      lienzo.lineTo(x + radius, y + height)
      lienzo.quadraticCurveTo(x, y + height, x, y + height - radius)
      lienzo.lineTo(x, y + radius)
      lienzo.quadraticCurveTo(x, y, x + radius, y)
      lienzo.closePath()

      if (fondo == 'si') {
        lienzo.fillStyle = color
        lienzo.fill();
      } else {
        lienzo.strokeStyle = color
        lienzo.lineWidth = grosor_borde
        lienzo.stroke();
      }
    },

    circuloCanvas: function(lienzo, x, y, radio, color, fondo='si', grosor_borde) {
      var self = this

      lienzo.beginPath();
      lienzo.arc(x, y, radio, 0, Math.PI * 2);
      lienzo.closePath();

      if (fondo == 'si') {
        lienzo.fillStyle = color
        lienzo.fill()
      } else {
        lienzo.strokeStyle = color;
        lienzo.lineWidth = grosor_borde
        lienzo.stroke();
      }
    },

    codigoQRCanvas:function(lienzo, index, x, y, width, height) {
      var self = this
      
      let qr_code = document.getElementById('qr-code-'+(index+1))
      var dataURL = qr_code.toDataURL()

      var qr = new Image()
      qr.src = dataURL

      qr.onload = function() {
        lienzo.drawImage(qr, x, y, width, height)
      }   
    },

    trianguloCanvas: function(lienzo, x, y, width, height, color, fondo='si', grosor_borde) {
      var self = this

      lienzo.beginPath();
      lienzo.moveTo(x + (width/2), y)
      lienzo.lineTo(x, y + height)
      lienzo.lineTo(x+width, y+height)
      lienzo.lineTo(x + (width/2), y);
      lienzo.closePath();

      if (fondo == 'si') {
        lienzo.fillStyle = color
        lienzo.fill()
      } else {
        lienzo.strokeStyle = color;
        lienzo.lineWidth = grosor_borde
        lienzo.stroke();
      }
    },

    agregarInfo (ficha, lienzo, index) {
      let self = this,
          layout = ficha.layout

      layout.datos.forEach(dato => {
        let d = self.datos.find(d => {
          return d.titulo == dato.titulo
        })

        let titulo
        if( d !== undefined ) titulo = d.valor(ficha, self.monedaSistema)
        else titulo = dato.titulo

        lienzo.font = dato.tamano+'px '+dato.fuente
        lienzo.fillStyle = dato.color

        lienzo.fillText(titulo, dato.x, dato.y)
      })
    },

    refrescarFichas (){
      var self = this

      self.fichas = []

      let params = {
        ids_fichas: self.ids_fichas
      }

      FichasSrv.fichasImprimirJSON(params).then(response => {
        self.fichas = response.data
        self.fichas.forEach(ficha => {
          var nuevo_layout = JSON.parse(ficha.layout.datos)

          Object.assign(nuevo_layout,{
            id: ficha.layout.id,
            nombre: ficha.layout.nombre,
            tipo_autenticacion: ficha.layout.tipo_autenticacion,
            created_at: ficha.layout.created_at,
            updated_at: ficha.layout.updated_at
          })

          ficha.layout = nuevo_layout
          ficha.nombre_empresa = self.nombre_empresa
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las fichas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.modificarEscala()
      })
    },

    cargarCanvas (){
      var self= this

      self.fichas.forEach((ficha,index) => {
        let canvas = document.getElementById(`x-${index+1}`)
        let lienzo = canvas.getContext('2d')
        let img_fondo = null
        let tipo_fondo = ficha.layout.tipo_fondo
        let fondo_cargado = ficha.layout.fondo_cargado 
        
        lienzo.clearRect(0,0, canvas.width, canvas.height)
        self.agregarDegradado(ficha, lienzo)

        if (tipo_fondo != 3) {
          self.agregarFigura(ficha, lienzo, index)
          self.completarImagenes(function() {
            self.agregarInfo(ficha, lienzo, index)
          }, ficha, lienzo, index)
        }
        else {
          img_fondo = new Image()
          img_fondo.src = fondo_cargado ? `${self.API}/fichas/layouts/${ficha.layout.id}/imagenes/fondo?_tk=${self.tk}` : ''
          img_fondo.onload = function() {
            lienzo.drawImage(img_fondo, 0, 0, canvas.width, canvas.height)
            self.agregarFigura(ficha, lienzo, index)
            self.completarImagenes(function() {
              self.agregarInfo(ficha, lienzo, index)
            }, ficha, lienzo, index)
          }
        }
      })
    },

    completarImagenes: function(callback, ficha, lienzo, index) {
      var self = this,
          layout = ficha.layout

      if(layout.imagenes.length == 0 && callback) callback()

      layout.imagenes.forEach(imagen => {
        if(imagen.id != '' && imagen.id != null){
          let img = new Image()

          let src = imagen.id == 'Usuario' ? self.usuarioicon :
            `${self.API}/fichas/layouts/${layout.id}/imagenes/${imagen.id}?_tk=${self.tk}`

          img.src = src

          img.onload = function() {
            lienzo.drawImage(img, imagen.x, imagen.y, imagen.width, imagen.height)
            if(callback) callback()
          }
        } else {
          if(callback) callback()
        }
      })
    },

    cerrar(){
      window.close()
    },

    imprimir(){
      window.print()
    },

    modificarEscala(){
      var self = this
      var escala = 1

      window.localStorage.setItem('argusblack.hotspot.fichas.ImprimirFichas.tipo_escala', self.tipo_escala)

      if(self.tipo_escala == 1) escala = (13*3) / 100
      else if(self.tipo_escala == 2) escala = (23*3) / 100
      else if(self.tipo_escala == 3) escala = (47*3) / 100
      else if(self.tipo_escala == 4) escala = (100 *3) / 100
      else if(self.tipo_escala == 5) {
        if(self.escala <= 10 || self.escala > 100) {
          iu.msg.warning('El porcentaje de escala no puede ser menor 11 o mayor a 100')
          self.$refs.escala.focus()
          return
        }
        escala = (self.escala *3) / 100
      }

      var scale = document.getElementById("scale")
      scale.setAttribute("style", `zoom: ${escala}; -moz-transform:scale(${escala});`)

      setTimeout(() => {
        self.cargarCanvas()
      }, 500)
    }
  },
  mounted() {
    var self = this
    setTimeout(() => {
      self.modificarEscala()
    }, 1000)
  }
}